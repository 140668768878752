import React, { useEffect, useState } from 'react';
import Carousel from 'nuka-carousel';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { RootState } from 'reducer';
import { imageUrl } from 'theme';
import { Message } from 'seedly-component-library';
import Dialog from 'seedly-component-library/dialog';
import Button from 'seedly-component-library/button-new';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { openJoinGroupModal } from 'dispatcher/modalDispatcher';
import { useIsSignedIn } from 'entity/user/userHooks';
import getSessionStorage from 'utils/getSesssionStorage';
import { Container, ArrowButton } from './styles';

const contentData = [
  {
    imgSrc: imageUrl.communityCarousel,
    title: 'Engage in Seedly Community',
    secondary:
      'Learn from and be part of the hottest discussions happening amongst Singaporeans like yourself',
  },
  {
    imgSrc: imageUrl.blogCarousel,
    title: 'Read the latest news from Blog',
    secondary:
      'Grow your financial knowledge through our easy-to-read and relatable Seedly articles',
  },
  {
    imgSrc: imageUrl.reviewsCarousel,
    title: 'Read and write authentic Reviews',
    secondary:
      'Read authentic user reviews about financial products like credit cards, robo-advisors, insurance and more',
  },
];

const Content = ({ imgSrc, title, secondary }) => {
  return (
    <div className="flex flex-col gap-3 justify-center items-center">
      <img
        alt="onboarding"
        src={imgSrc}
        height={160}
        width={240}
        className="object-contain"
      />
      <p className="text-base font-bold">{title}</p>
      <p className="text-neutral-500 text-center">{secondary}</p>
    </div>
  );
};

const SignUpOnboardingModal = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const isSignedin = useIsSignedIn();
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    const userSignedUp = getSessionStorage('user-signedup');

    if (userSignedUp === 'true' && isSignedin) {
      Message.success('Account created');
      setIsOpen(true);
    }
  }, [isSignedin, user.createdAt]);

  if (!isSignedin) return null;

  return (
    <Dialog
      isOpen={isOpen}
      onCloseClick={() => {
        mixpanel.track(trackedEvents.LoginSignUpModal, {
          param: 'close_carousel',
        });
        sessionStorage.removeItem('user-signedup');
        setIsOpen(false);
      }}
      contentClassName="justify-center"
      widthSize="sm"
    >
      <p className="text-xl text-center font-bold mb-6">
        Welcome
        <br />
        {user.name}!
      </p>
      <Container>
        <Carousel
          autoplay
          autoplayInterval={3000}
          pauseOnHover
          swiping
          wrapAround
          renderCenterLeftControls={({ previousSlide }) => {
            return (
              <ArrowButton
                className="arrow-button-left"
                onClick={previousSlide}
              >
                <ChevronLeft size={22} color="blue" />
              </ArrowButton>
            );
          }}
          renderCenterRightControls={({ nextSlide }) => {
            return (
              <ArrowButton className="arrow-button-right" onClick={nextSlide}>
                <ChevronRight size={22} color="blue" />
              </ArrowButton>
            );
          }}
          defaultControlsConfig={{
            pagingDotsContainerClassName: 'dots-container',
          }}
        >
          {contentData.map(data => (
            <Content
              key={data.imgSrc}
              imgSrc={data.imgSrc}
              title={data.title}
              secondary={data.secondary}
            />
          ))}
        </Carousel>
      </Container>
      <div className="px-4 pt-8">
        <Button
          isBlock
          onClick={() => {
            mixpanel.track(trackedEvents.LoginSignUpModal, {
              param: 'continue_onboarding',
            });
            dispatch(openJoinGroupModal({ isSigningUp: true }));
            sessionStorage.removeItem('user-signedup');
            setIsOpen(false);
          }}
        >
          Continue
        </Button>
      </div>
    </Dialog>
  );
};

export default SignUpOnboardingModal;
