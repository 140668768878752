import styled from 'styled-components';
import { media } from 'theme';

export const Container = styled.div`
  .dots-container {
    top: 30px !important; // override the default fixed position
  }

  .arrow-button-left {
    left: 25px;
  }

  .arrow-button-right {
    right: 30px;
  }

  .slider-slide {
    text-align: center;
  }
`;

export const ArrowButton = styled.button`
  position: absolute;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: ${props => props.theme.colors.blue1};
  cursor: pointer;

  ${media.largeAndDown`
    opacity: 0;
  `}
`;
